import React, { useEffect, useState, useRef } from 'react';
import { isBlockVisibleOnScreen } from "helpers/utils";
import cn from 'classnames';
import WWD from 'assets/images/WWD Icon flat.jpg';
import DS from 'assets/images/DitoStable.png';
import AWS from 'assets/images/fgb.png';
import Verb from 'assets/images/VB.png';
import FAB from 'assets/images/shake_hands.png';
import { useIsMobile } from "hooks";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';

export const SolutionsSection5 = () => {
  const section1Ref = useRef();
  const section2Ref = useRef();
  const section3Ref = useRef();
  const section4Ref = useRef();
  const section5Ref = useRef();
  const [active1, setActive1] = useState(false);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [active4, setActive4] = useState(false);
  const [active5, setActive5] = useState(false);
  const mobile = useIsMobile();

  useEffect(() => {
    if (mobile) {
      setActive1(true);
      setActive2(true);
      setActive3(true);
      setActive4(true);
      setActive5(true);
    } else {
      isBlockVisibleOnScreen(section1Ref.current, setActive1, [0, 1]);
      isBlockVisibleOnScreen(section2Ref.current, setActive2, [0, 1]);
      isBlockVisibleOnScreen(section3Ref.current, setActive3, [0, 1]);
      isBlockVisibleOnScreen(section4Ref.current, setActive4, [0, 1]);
      isBlockVisibleOnScreen(section5Ref.current, setActive5, [0, 1]);
    }
  }, [mobile]);

  return (
    <section className='flex flex-col items-center relative sm:mt-32 sm:px-10'>
      <span className='border border-1 border-white rounded-full absolute -top-1/3 -left-[55%] w-[104vw] h-[104vw] sm:hidden' />
      <span className='border border-1 border-white rounded-full absolute top-0 -right-1/2 w-[105vw] h-[105vw] sm:hidden' />
      {mobile ?
        <div className='relative w-full'>
          <Swiper
            navigation={{
              prevEl: '.prev',
              nextEl: '.next',
            }}
            slidesPerView={1}
            spaceBetween={80}
            modules={[Navigation]}
            className="w-full"
          >

            <SwiperSlide className='z-20 group'>
              <div className='flex flex-col scale-75 items-center duration-150 delay-100 origin-top group-[.swiper-slide-active]:scale-100'>
                <img className={cn('w-[calc(100vw-100px)] h-[calc(100vw-100px)] object-cover rounded-full border-4 border-white p-3')} src={WWD} />
                <div className='flex flex-col mt-10'>
                  <p className={`text-white text-3xl mb-10 font-bold text-center ${active1 ? 'duration-300 translate-x-0 opacity-100' : 'duration-0 -translate-x-1/4 opacity-0'}`}>A Day in the Office:<br /> Face Disability Bias</p>
                  <p className={`text-white text-base text-justify font-normal ${active1 ? 'duration-300 delay-100 translate-x-0 opacity-100' : 'duration-0 -translate-x-1/4 opacity-0'}`}>
                    A Day in the Office: Face Disability Bias immerses you in the life of Emma,
                    a 30-year-old woman battling chronic fatigue and other undiagnosed symptoms.
                    As you go through the day,
                    you will experience firsthand how simple actions like opening a door or checking
                    an email can become unsurmountable challenges if the body fails us.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='-20 group'>
              <div className='flex flex-col scale-75 items-center duration-150 delay-100 origin-top group-[.swiper-slide-active]:scale-100'>
                <img className={cn('w-[calc(100vw-100px)] h-[calc(100vw-100px)] object-cover rounded-full border-4 border-white p-3')} src={DS} />
                <div className='flex flex-col mt-10'>
                  <p className={`text-white text-3xl mb-10 font-bold text-center ${active2 ? 'duration-300 translate-x-0 opacity-100' : 'duration-0 translate-x-1/4 opacity-0'}`}>A Day in The Office: <br />Face Gender Bias </p>
                  <p className={`text-white text-base text-justify font-normal ${active2 ? 'duration-300 delay-100 translate-x-0 opacity-100' : 'duration-0 translate-x-1/4 opacity-0'}`}>A Day in the Office: Face Gender Bias Virtual Reality training allows trainees to see the world through the eyes of a colleague named Anna. Using embodiment, participants become Anna as she goes about her workday: participating in meetings, interacting with colleagues, and navigating biases.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='z-20 group'>
              <div className='flex flex-col scale-75 items-center duration-150 delay-100 origin-top group-[.swiper-slide-active]:scale-100'>
                <img className={cn('w-[calc(100vw-100px)] h-[calc(100vw-100px)] object-cover rounded-full border-4 border-white p-3')} src={AWS} />
                <div className='flex flex-col mt-10'>
                  <p className={`text-white text-3xl mb-10 font-bold text-center ${active3 ? 'duration-300 translate-x-0 opacity-100' : 'duration-0 -translate-x-1/4 opacity-0'}`}>A Day in Space:<br /> Face Gender Bias </p>
                  <p className={`text-white text-base text-justify font-normal ${active3 ? 'duration-300 delay-100 translate-x-0 opacity-100' : 'duration-0 -translate-x-1/4 opacity-0'}`}>A Day in Space: Face Gender Bias transports you into the body of Cosmonaut Turova, inspired by the story of the first woman in space Valentina Tereshkova, navigating the male-dominated world of space-industry.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='z-20 group'>
              <div className='flex flex-col scale-75 items-center duration-150 delay-100 origin-top group-[.swiper-slide-active]:scale-100'>
                <img className={cn('w-[calc(100vw-100px)] h-[calc(100vw-100px)] object-cover rounded-full border-4 border-white p-3')} src={Verb} />
                <div className='flex flex-col mt-10'>
                  <p className={`text-white text-3xl mb-10 font-bold text-center ${active4 ? 'duration-300 translate-x-0 opacity-100' : 'duration-0 translate-x-1/4 opacity-0'}`}>A Regular Day:<br /> Face Racial Bias</p>
                  <p className={`text-white text-base text-justify font-normal ${active4 ? 'duration-300 delay-100 translate-x-0 opacity-100' : 'duration-0 translate-x-1/4 opacity-0'}`}>A Regular Day: Face Racial Bias allows participants to engage in simple, relatable activities, while observing themselves carrying their new bodies of a different race.</p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide className='z-20 group'>
              <div className='flex flex-col scale-75 items-center duration-150 delay-100 origin-top group-[.swiper-slide-active]:scale-100'>
                <img className={cn('w-[calc(100vw-100px)] h-[calc(100vw-100px)] object-cover rounded-full border-4 border-white p-3')} src={FAB} />
                <div className='flex flex-col mt-10'>
                  <p className={`text-white text-3xl mb-10 font-bold text-center ${active5 ? 'duration-300 translate-x-0 opacity-100' : 'duration-0 translate-x-1/4 opacity-0'}`}>First Day At Work: <br /> Face Age Bias</p>
                  <p className={`text-white text-base text-justify font-normal ${active5 ? 'duration-300 delay-100 translate-x-0 opacity-100' : 'duration-0 translate-x-1/4 opacity-0'}`}>First Day at Work: Face Age Bias is a VR experience designed to foster empathy
                    and understanding of age diversity in the workplace. The training offers participants the
                    unique opportunity to embody avatars that represents both young and older employees - each
                    facing distinct forms of workplace age-based discrimination.</p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <span style={{ clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)' }} className='prev absolute top-[calc(50vw-40px)] -left-1 -translate-y-1/2  -translate-x-full z-50 w-5 h-5 bg-gray -rotate-90 cursor-pointer duration-150 [&.swiper-button-disabled]:opacity-30'></span>
          <span style={{ clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)' }} className='next absolute top-[calc(50vw-40px)] -right-1 -translate-y-1/2 translate-x-full  z-50 w-5 h-5 bg-gray rotate-90 cursor-pointer duration-150 [&.swiper-button-disabled]:opacity-30'></span>
        </div> :
        <>
          <div className='relative flex items-center z-20 w-full pl-32 mt-32 lg:pl-16 md:!pl-10'>
            <div className='flex flex-col max-w-[30rem] mr-12 lg:mr-8 lg:max-w-[20rem]'>
              <p className={`text-white text-4xl mb-10 font-bold lg:text-2xl ${active1 ? 'duration-300 translate-x-0 opacity-100' : 'duration-0 -translate-x-1/4 opacity-0'}`}>A Day in the Office:<br /> Face Disability Bias</p>
              <p className={`text-white text-base text-justify font-normal lg:text-sm ${active1 ? 'duration-300 delay-100 translate-x-0 opacity-100' : 'duration-0 -translate-x-1/4 opacity-0'}`}>
                A Day in the Office: Face Disability Bias immerses you in the life of Emma,
                a 30-year-old woman battling chronic fatigue and other undiagnosed symptoms. As you go through the day, you will experience
                firsthand how simple actions like opening a door or checking an email can become unsurmountable challenges if the body fails us.
              </p>
            </div>
            <img ref={section1Ref} className={cn('w-full h-[33vw] object-cover rounded-l-[5rem] translate-x-full duration-300 ease-in-out md:max-w-[24rem] ', { '!translate-x-0': active1 })} src={WWD} />
          </div>
          <div className='relative flex items-center z-20 w-full pr-32 mt-32 lg:pr-16 md:!pr-10'>
            <img ref={section2Ref} className={cn('w-full h-[33vw] object-cover rounded-r-[5rem] -translate-x-full duration-300 ease-in-out  md:max-w-[24rem]', { '!translate-x-0': active2 })} src={DS} />
            <div className='flex flex-col max-w-[30rem] ml-12 lg:ml-8 lg:max-w-[20rem]'>
              <p className={`text-white text-4xl mb-10 font-bold  lg:text-2xl ${active2 ? 'duration-300 translate-x-0 opacity-100' : 'duration-0 translate-x-1/4 opacity-0'}`}>A Day in the Office: <br />Face Gender Bias</p>
              <p className={`text-white text-base text-justify font-normal lg:text-sm ${active2 ? 'duration-300 delay-100 translate-x-0 opacity-100' : 'duration-0 translate-x-1/4 opacity-0'}`}>A Day in the Office: Face Gender Bias Virtual Reality training allows trainees to see the world through the eyes of a colleague named Anna. Using embodiment, participants become Anna as she goes about her workday: participating in meetings, interacting with colleagues, and navigating biases.</p>
            </div>
          </div>
          <div className='relative flex items-center z-20 w-full pl-32 mt-32 lg:pl-16 md:!pl-10'>
            <div className='flex flex-col max-w-[30rem] mr-12 lg:mr-8 lg:max-w-[20rem]'>
              <p className={`text-white text-4xl mb-5 font-bold  lg:text-2xl ${active3 ? 'duration-300 translate-x-0 opacity-100' : 'duration-0 -translate-x-1/4 opacity-0'}`}>A Day in Space:<br /> Face Gender Bias </p>
              <p className={`text-white text-base text-justify font-normal lg:text-sm ${active3 ? 'duration-300 delay-100 translate-x-0 opacity-100' : 'duration-0 -translate-x-1/4 opacity-0'}`}>A Day in Space: Face Gender Bias transports you into the body of Cosmonaut Turova, inspired by the story of the first woman in space Valentina Tereshkova, navigating the male-dominated world of space-industry.</p>
            </div>
            <img ref={section3Ref} className={cn('w-full h-[33vw] object-cover rounded-l-[5rem] translate-x-full duration-300 ease-in-out  md:max-w-[24rem]', { '!translate-x-0': active3 })} src={AWS} />
          </div>
          <div className='relative flex items-center z-20 w-full pr-32 mt-32 lg:pr-16 md:!pr-10'>
            <img ref={section4Ref} className={cn('w-full h-[33vw] object-cover rounded-r-[5rem] -translate-x-full duration-300 ease-in-out  md:max-w-[24rem]', { '!translate-x-0': active4 })} src={Verb} />
            <div className='flex flex-col max-w-[30rem] ml-12 lg:mr-8 lg:max-w-[20rem]'>
              <p className={`text-white text-4xl mb-5 font-bold  lg:text-2xl ${active4 ? 'duration-300 translate-x-0 opacity-100' : 'duration-0 translate-x-1/4 opacity-0'}`}>A Regular Day:<br /> Face Racial Bias</p>
              <p className={`text-white text-base text-justify font-normal lg:text-sm ${active4 ? 'duration-300 delay-100 translate-x-0 opacity-100' : 'duration-0 translate-x-1/4 opacity-0'}`}>A Regular Day: Face Racial Bias allows participants to engage in simple, relatable activities, while observing themselves carrying their new bodies of a different race.</p>
            </div>
          </div>
          <div className='relative flex items-center z-20 w-full pl-32 mt-32 lg:pl-16 md:!pl-10'>
            <div className='flex flex-col max-w-[30rem] mr-12 lg:mr-8 lg:max-w-[20rem]'>
              <p className={`text-white text-4xl mb-5 font-bold  lg:text-2xl ${active5 ? 'duration-300 translate-x-0 opacity-100' : 'duration-0 -translate-x-1/4 opacity-0'}`}>First Day At Work: <br /> Face Age Bias </p>
              <p className={`text-white text-base text-justify font-normal lg:text-sm ${active5 ? 'duration-300 delay-100 translate-x-0 opacity-100' : 'duration-0 -translate-x-1/4 opacity-0'}`}>First Day at Work: Face Age Bias is a VR experience designed to foster empathy
                and understanding of age diversity in the workplace. The training offers participants the
                unique opportunity to embody avatars that represents both young and older employees - each
                facing distinct forms of workplace age-based discrimination.</p>
            </div>
            <img ref={section5Ref} className={cn('w-full h-[33vw] object-cover rounded-l-[5rem] translate-x-full duration-300 ease-in-out  md:max-w-[24rem]', { '!translate-x-0': active5 })} src={FAB} />
          </div>
        </>}
    </section>
  );
};

