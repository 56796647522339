import React, { useEffect, useState, useRef } from 'react';
import SectionBg from 'assets/images/Alexis-HS.webp';
import { isBlockVisibleOnScreen } from "helpers/utils";
import { useIsMobile } from "hooks";

export const HomeSection8 = () => {
  const sectionRef = useRef();
  const [active, setActive] = useState(false);
  const mobile = useIsMobile();

  useEffect(() => {
    isBlockVisibleOnScreen(sectionRef.current, setActive, [0, 1]);
  }, []);

  return (
    <div ref={sectionRef} className='flex relative pt-[57.5%] overflow-hidden md:pt-[206%] sm:!pt-[300%]'>
      <span className='absolute bottom-0 right-0 w-full h-full  bg-blue-gradient-to-right z-10 pointer-events-none md:hidden'></span>
      <img className='absolute top-0 left-0 w-full object-contain filter grayscale md:object-cover md:h-full md:object-[35%] md:brightness-50' src={SectionBg} alt="vr background" loading="lazy" />
      <div className='absolute flex items-center  justify-between top-1/2 -translate-y-1/2 left-28 right-28 z-20 xl:left-16 xl:right-16 md:flex-col md:!left-8 md:!right-8'>
        <div className='flex-1 mr-36 max-w-4xl xl:mr-32 min-lg:!mr-20 md:!mr-0 md:text-justify min-md:mt-40'>
          <p className={`text-5xl font-semibold text-white duration-300 xl:text-3xl  ${active ? ' translate-x-0 opacity-100' : '-translate-x-1/4 opacity-0'}`}>Reach out to us!</p>
          <p className={`text-2xl font-semibold text-white my-14 duration-300 xl:text-lg xl:my-8 md:text-base md:!mt-6 md:!mb-4  ${active ? ' delay-100 translate-x-0  opacity-100' : '-translate-x-1/4 opacity-0'}`}>The future of learning is here — are you ready? If you’ve got a training objective that feels impossible to reach—if you’ve struggled to find a way to get your team excited about learning—if you need scalable solutions for upskilling your workforce —you need to talk to us.</p>
        </div>
        {mobile ?
          <div className='flex max-w-[30rem] h-[40rem] w-full sm:mt-8 sm:min-w-full'>
            <iframe width='100%' height='100%' src="https://share-eu1.hsforms.com/1CxxCWHuSQE2wBnEnZUtTOg2elq82"></iframe>
          </div> :
          <div className='flex max-w-[30rem] h-[40rem] lg:h-[30rem] md:!h-[70rem] w-full sm:mt-8 sm:min-w-full'>
            <iframe width='100%' height='100%' src="https://share-eu1.hsforms.com/1CxxCWHuSQE2wBnEnZUtTOg2elq82"></iframe>
          </div>
        }
      </div>
    </div>
  );
};
