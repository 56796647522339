import React from 'react';
import Dots from 'assets/images/mihai-landing-page/black-gradient.png';
import StanVR from 'assets/images/mihai-landing-page/Dan-HS.webp';
import classNames from 'classnames';

export const FutureWithVR = () => {
  return (
    <div className='flex justify-center relative w-full'>
      <div className='flex items-center w-full min-xl:px-32 px-16 lg:px-20 lg:py-10 lg:justify-end'>
        <img className={`w-[48.3%] z-10 -my-2.5 rounded-[3rem] lg:my-0 lg:absolute lg:top-1/2 lg:left-20 lg:-translate-y-1/2 lg:brightness-50	`} src={StanVR} alt="transform leadership with technology" />
        <div className={classNames('flex flex-col justify-start text-white text-6xl ml-14 font-anton z-30 leading-snug tracking-wider flex-1',
          'lg:text-5xl lg:leading-snug lg:ml-0 lg:max-w-lg lg:items-end'
        )}>
          <p>LEAD YOUR</p>
          <p>COMPANY INTO</p>
          <p className='text-orange'>THE FUTURE WITH VR</p>
          <p className='text-white text-2xl font-roboto mt-10 lg:text-xl lg:mt-8 lg:mb-0 lg:max-w-lg lg:text-right'>Learn how top companies are utilizing VR and AI to boost sales success, cultivate inclusive leadership, and strengthen resilience. Don&apos;t miss this chance to advance your leadership approach and expand your training solutions worldwide.</p>
          <p className='text-white text-2xl font-roboto mt-10 lg:text-xl lg:mt-8 lg:mb-0 lg:max-w-lg'>Let&apos;s lead the future, together!</p>
          <a
            target='_blank'
            className='self-start lg:self-end mt-16 font-roboto font-bold uppercase text-2xl !leading-none py-3.5 px-6 bg-white rounded-xl text-black duration-200	border-2 border-white min-lg:hover:bg-orange min-lg:hover:text-white min-lg:!hover:delay-0 xl:text-base md:py-3 md:px-5 md:!text-xl md:!rounded-2xl md:!leading-none lg:mt-10'
            href="https://www.eventbrite.com/o/wondder-66023813013"
            rel="noreferrer">
            See Upcoming events
          </a>
        </div>
      </div>
      <img className={`absolute top-0 left-0 w-full h-full object-cover`} src={Dots} alt="dots" />
    </div>
  );
};
