export const successStoriesPartners = {
  'bmw': {
    title: "Discover How BMW Transformed Leadership Training with Immersive VR",
    subTitle: "A scalable solution to foster empathy, address unconscious bias, and enhance leadership skills.",
    content: "BMW partnered with wondder to revolutionize its leadership development approach, addressing critical challenges in fostering empathy, tackling unconscious bias, and teaching actionable feedback techniques. Using wondder’s immersive VR training combined with expert coaching, BMW delivered a series of impactful workshops tailored to leaders at various levels.",
    pdf: 'caseStudies/wondder VR - BMW.pdf',
    PDFPreview: '/static/bmw-pdf.png',
    formLink:'1HG5508PtSrOLA4q4K_tchg2elq82',
    highlights: [
      {
        "● High Engagement": "VR captivated participants, making abstract concepts like bias and feedback tangible and relatable.",
      },
      { "● Behavioral Change": "Leaders reported a deeper understanding of their biases and greater confidence in handling sensitive topics" },
      { "● Scalable Solutions": "Workshops demonstrated the potential to expand VR training across BMW’s global leadership programs." },
    ],
    workshops: [
      {
        '● Bias Training': "Realistic scenarios challenged participants to reflect on biases and fostered empathy.",
      },
      {
        '● Feedback Training': "Leaders practiced actionable feedback techniques in a hands-on VR environment.",
      },
      {
        '● Foundational Leadership Training': "Emerging leaders engaged in self-discovery and aligned their values with company goals.",
      }
    ],
    resultsThatMatter: [
      "● Leaders built a more inclusive and empathetic workplace culture.",
      '● Teams benefited from improved feedback practices that fostered growth and collaboration.',
      "● BMW set a new standard for leadership innovation within the automotive industry.",
    ]
  },
  'hugo-boss': {
    title: "Discover How Hugo Boss is Setting the Standard for Inclusive Workplaces",
    subTitle: "See how immersive VR training transformed diversity and inclusion efforts for a global workforce.",
    content: "Hugo Boss faced a critical challenge: fostering inclusion and empathy within a multinational workforce while addressing unconscious gender bias and microaggressions. Traditional diversity training methods fell short, so they turned to Wondder for an innovative solution. With our “Day in the Office” VR training module, Hugo Boss empowered employees to step into realistic scenarios, sparking self-reflection, meaningful discussions, and measurable change.",
    pdf: 'caseStudies/wondder VR - Hugo Boss.pdf',
    PDFPreview: '/static/boss-pdf.png',
    formLink:'1nhP0zbRqQvetqOy3W3bqxQ2elq82',
    highlights: [
      {
        "● 97% Positive Feedback": "VR captivated participants, making abstract concepts like bias and feedback tangible and relatable.",
      },
      { "● High Engagement": "VR created a captivating, hands-on learning environment." },
      { "● Seamless Scalability": "Consistent training delivery across multiple departments and locations." },
    ],
    resultsThatMatter: [
      "●	Encouraged self-awareness and empathy among employees.",
      '●  Helped HR teams address bias effectively',
      "●	Fostered a more collaborative, inclusive workplace culture.",
    ]
  },
  'accenture': {
    title: "Discover How Accenture Transformed Diversity Training with VR",
    subTitle: "A scalable solution for fostering empathy, addressing bias, and building an inclusive workplace culture.",
    content: 'With a global workforce of 774,000 employees across 52 countries, Accenture needed a cutting-edge solution to drive inclusion, foster empathy, and address unconscious bias. They partnered with wondder to deliver measurable results through the innovative "Day in the Office" VR training program.',
    pdf: 'caseStudies/wondder VR - Accenture.pdf',
    PDFPreview: '/static/accenture-pdf.png',
    formLink:'1kCNrjqI9SpS80Wbh6dctVQ2elq82',
    highlights: [
      { "● 95% Positive Feedback": "Employees praised the relevance and impact of the training." },
      { "● Scalable Solutions": "Delivered consistent training across multiple departments and locations over 24 months and ongoing." },
      { "● High Engagement": "Immersive VR created a captivating, hands-on learning experience." },
      { false: "● Strengthened the ability to recognize and address bias." },
      { false: "● Fostered workplace collaboration and respect" },
    ],
    ctaTitle: "Download the full case study and learn how wondder’s VR technology can transform your Diversity and Inclusion initiatives."
  }
};

