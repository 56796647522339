import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Logo, OrangeButton } from "components";
import { useWindowDimensions } from "hooks";
import { AlessioHubspot } from 'helpers/constants';
import cn from 'classnames';

export const Header = () => {
  const [isOpened, setIsOpened] = useState(false);
  const [isSubMenuOpened, setIsSubMenuOpened] = useState({
    first: false,
    second: false,
    third: false,
  });
  const { width } = useWindowDimensions();
  const location = useLocation();

  useEffect(() => {
    if (!window.location.href.includes('#')) window.scrollTo(0, 0);
    setIsSubMenuOpened({
      first: false,
      second: false,
    });
  }, [location]);

  if (window.location.pathname === '/mihai') return null;

  return (
    <div
      className={`flex items-center bg-white px-10 fixed top-0 left-0 right-0 z-[100] w-full xl:px-8 ${width < 900 ? 'justify-between' : ''}`}
      onMouseLeave={() => setIsSubMenuOpened({
        first: false,
        second: false,
      })}>
      <Logo onClick={() => setIsOpened(false)} />
      {width < 900 ?
        <>
          <div onClick={() => setIsOpened(!isOpened)} className='burger flex flex-col justify-between relative w-8 h-6 cursor-pointer'>
            <span className={cn("w-full h-0.5 bg-black duration-300", { '-rotate-45 origin-top-right': isOpened })}></span>
            <span className={cn("w-full h-0.5 bg-black duration-300", { '!w-0': isOpened })}></span>
            <span className={cn("w-full h-0.5 bg-black duration-300", { 'rotate-45 origin-bottom-right': isOpened })}></span>
          </div>
          <div className={
            cn("flex flex-col items-end fixed pt-20 px-10 bg-white top-16 left-0 right-0 bottom-0 z-[100] duration-300 ease-in-out	",
              { 'translate-x-0 pointer-events-all': isOpened },
              { 'translate-x-full pointer-events-none': !isOpened })
          }>
            <nav className='flex flex-col mb-10 text-right'>
              <Link onClick={() => setIsOpened(false)} className='mb-3 text-black text-4xl' to="/our-solutions">Our solutions</Link>
              <div className={`flex flex-col gap-y-2 mb-4`}>
                <HashLink onClick={() => setIsOpened(false)} className='text-black duration-200 text-lg' to="/our-solutions#leadership-development">Leadership Development</HashLink>
                <HashLink onClick={() => setIsOpened(false)} className='text-black duration-200 text-lg' to="/our-solutions#diversity-equity-inclusion">Diversity, Equity, Inclusion</HashLink>
                <HashLink onClick={() => setIsOpened(false)} className='text-black duration-200 text-lg' to="/our-solutions#wellbeing">Wellbeing</HashLink>
              </div>
              <Link onClick={() => setIsOpened(false)} className='mb-4 text-black text-4xl' to="/success-stories">Success Stories</Link>
              <div className={`flex flex-col gap-y-2 mb-4`}>
                <Link onClick={() => setIsOpened(false)} className='text-black duration-200 text-lg' to="/success-story/bmw">BMW Case Study</Link>
                <Link onClick={() => setIsOpened(false)} className='text-black duration-200 text-lg' to="/success-story/accenture">Accenture Case Study</Link>
                <Link onClick={() => setIsOpened(false)} className='text-black duration-200 text-lg' to="/success-story/hugo-boss">Hugo Boss Case Study</Link>
              </div>
              <Link onClick={() => setIsOpened(false)} className='mb-4 text-black text-4xl' to="/about-us">About Us</Link>
              <Link onClick={() => setIsOpened(false)} className='mb-4 text-black text-4xl' to="/team">Team</Link>
              <Link onClick={() => setIsOpened(false)} className='mb-4 text-black text-4xl' to="/contact">Contact</Link>
              <div className={`flex flex-col gap-y-2 mb-4`}>
                <Link onClick={() => setIsOpened(false)} className='text-black duration-200 text-lg' to="/hr-and-ld-partners">HR and LD Partners</Link>
                <Link onClick={() => setIsOpened(false)} className='text-black duration-200 text-lg' to="/global-partner-network">Global Partner Network</Link>
              </div>
              {/* <Link onClick={() => setIsOpened(false)} className='mb-4 text-black text-4xl' to="/blog">Blog</Link> */}
            </nav>
            <OrangeButton target="_blank" href={AlessioHubspot} className='!p-2.5 !text-2xl'>Book a Demo</OrangeButton>
          </div>
        </>
        :
        <>
          <nav className='flex items-center ml-auto	' >
            <div className='relative mr-12 xl:mr-6'>
              <Link onMouseEnter={() => setIsSubMenuOpened({ first: true, second: false, third: false })} className='text-black duration-200 text-xl min-lg:hover:text-orange xl:text-base' to="/our-solutions">Our solutions</Link>
              <div onMouseLeave={() => setIsSubMenuOpened({ first: false, second: false, third: false })} className={`flex flex-col gap-y-2 bg-white p-3 rounded-md absolute top-[calc(100%+8px)] left-0 shadow duration-150 ${isSubMenuOpened.first ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}>
                <HashLink className='text-black duration-200 text-base min-lg:hover:text-orange whitespace-nowrap' to="/our-solutions#leadership-development">Leadership Development</HashLink>
                <HashLink className='text-black duration-200 text-base min-lg:hover:text-orange whitespace-nowrap' to="/our-solutions#diversity-equity-inclusion">Diversity, Equity, Inclusion</HashLink>
                <HashLink className='text-black duration-200 text-base min-lg:hover:text-orange whitespace-nowrap' to="/our-solutions#wellbeing">Wellbeing</HashLink>
              </div>
            </div>
            <div className='relative mr-12 xl:mr-6'>
              <Link onMouseEnter={() => setIsSubMenuOpened({ first: false, second: true, third: false })} className='text-black duration-200 text-xl min-lg:hover:text-orange xl:text-base' to="/success-stories">Success Stories</Link>
              <div onMouseLeave={() => setIsSubMenuOpened({ first: false, second: false, third: false })} className={`flex flex-col gap-y-2 bg-white p-3 rounded-md absolute top-[calc(100%+8px)] left-0 shadow duration-150 ${isSubMenuOpened.second ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}>
                <Link className='text-black duration-200 text-base min-lg:hover:text-orange whitespace-nowrap' to="/success-story/bmw">BMW Case Study</Link>
                <Link className='text-black duration-200 text-base min-lg:hover:text-orange whitespace-nowrap' to="/success-story/accenture">Accenture Case Study</Link>
                <Link className='text-black duration-200 text-base min-lg:hover:text-orange whitespace-nowrap' to="/success-story/hugo-boss">Hugo Boss Case Study</Link>
              </div>
            </div>
            <Link className='mr-12 text-black duration-200 text-xl min-lg:hover:text-orange xl:text-base xl:mr-6' to="/about-us">About Us</Link>
            <Link className='mr-12 text-black duration-200 text-xl min-lg:hover:text-orange xl:text-base xl:mr-6' to="/team">Team</Link>
            <div className='relative mr-12 xl:mr-6'>
              <Link onMouseEnter={() => setIsSubMenuOpened({ first: false, second: false, third: true })} className='text-black duration-200 text-xl min-lg:hover:text-orange xl:text-base' to="/contact">Contact</Link>
              <div onMouseLeave={() => setIsSubMenuOpened({ first: false, second: false, third: false })} className={`flex flex-col gap-y-2 bg-white p-3 rounded-md absolute top-[calc(100%+8px)] left-0 shadow duration-150 ${isSubMenuOpened.third ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}`}>
                <Link className='text-black duration-200 text-base min-lg:hover:text-orange whitespace-nowrap' to="/hr-and-ld-partners">HR and LD Partners</Link>
                <Link className='text-black duration-200 text-base min-lg:hover:text-orange whitespace-nowrap' to="/global-partner-network">Global Partner Network</Link>
              </div>
            </div>
            {/* <Link className='mr-12 text-black duration-200 text-xl min-lg:hover:text-orange xl:text-base xl:mr-6' to="/blog">Blog</Link> */}
          </nav>
          <OrangeButton target="_blank" href={AlessioHubspot} >Book a Demo</OrangeButton>
        </>
      }
    </div>
  );
};
