import React, { useEffect, useState, useRef } from 'react';
import { isBlockVisibleOnScreen } from "helpers/utils";
import cn from 'classnames';
import { useIsMobile } from "hooks";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import TTM from 'assets/images/TTM youtube cover HD flat no border (1).jpg';

export const SolutionsSection7 = () => {
  const section1Ref = useRef();
  const [active1, setActive1] = useState(false);
  const mobile = useIsMobile();

  useEffect(() => {
    if (mobile) {
      setActive1(true);
    } else {
      isBlockVisibleOnScreen(section1Ref.current, setActive1, [0, 1]);
    }
  }, [mobile]);

  return (
    <section className='flex flex-col items-center relative px-32 lg:px-12 sm:mt-32 sm:!px-10'>
      <div className='absolute right-0 bg-[#d9d9d9] rounded-s-full w-[60vw] h-[120vw] -top-[25%] md:hidden' />
      {mobile ?
        <div className='relative w-full'>
          <Swiper
            navigation={{
              prevEl: '.prev',
              nextEl: '.next',
            }}
            slidesPerView={1}
            spaceBetween={80}
            modules={[Navigation]}
            className="w-full"
          >
            <SwiperSlide className='-20 group'>
              <div className='flex flex-col scale-75 items-center duration-150 delay-100 origin-top group-[.swiper-slide-active]:scale-100'>
                <img className={cn('w-[calc(100vw-100px)] h-[calc(100vw-100px)] object-cover rounded-full border-4 border-white p-3')} src={TTM} />
                <div className='flex flex-col mt-10'>
                  <p className={`text-white text-3xl mb-10 font-bold text-center ${active1 ? 'duration-300 translate-x-0 opacity-100' : 'duration-0 translate-x-1/4 opacity-0'}`}>Self Reflection: <br />Unlock Solution Finding </p>
                  <p className={`text-white text-base text-justify font-normal ${active1 ? 'duration-300 delay-100 translate-x-0 opacity-100' : 'duration-0 translate-x-1/4 opacity-0'}`}>Immerse yourself in our revolutionary VR Self-Talk Experience, where confronting challenges and unlocking your potential takes center stage. Engage in a virtual dialogue with your inner self to find answers, break through mental blocks, and foster personal growth. </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <span style={{ clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)' }} className='prev absolute top-[calc(50vw-40px)] -left-1 -translate-y-1/2  -translate-x-full z-50 w-5 h-5 bg-gray -rotate-90 cursor-pointer duration-150 [&.swiper-button-disabled]:opacity-30'></span>
          <span style={{ clipPath: 'polygon(50% 0%, 0% 100%, 100% 100%)' }} className='next absolute top-[calc(50vw-40px)] -right-1 -translate-y-1/2 translate-x-full  z-50 w-5 h-5 bg-gray rotate-90 cursor-pointer duration-150 [&.swiper-button-disabled]:opacity-30'></span>
        </div>
        : <>
          <div className='flex items-center justify-between z-20 w-full mt-32	lg:mt-20'>
            <img ref={section1Ref} className={cn('w-[46vw] h-[26vw] object-cover rounded-[5rem] -translate-x-full duration-300 ease-in-out opacity-0', { '!translate-x-0 opacity-100': active1 })} src={TTM} />
            <div className='flex flex-col max-w-[30rem] ml-12 lg:ml-8'>
              <p className={`text-blue text-4xl mb-10 font-bold md:text-white ${active1 ? 'duration-300 translate-x-0 opacity-100' : 'duration-0 translate-x-1/4 opacity-0'}`}>Self Reflection: <br />Unlock Solution Finding </p>
              <p className={`text-blue text-base text-justify font-normal md:text-white  ${active1 ? 'duration-300 delay-100 translate-x-0 opacity-100' : 'duration-0 translate-x-1/4 opacity-0'}`}>Immerse yourself in our revolutionary VR Self-Talk Experience, where confronting challenges and unlocking your potential takes center stage. Engage in a virtual dialogue with your inner self to find answers, break through mental blocks, and foster personal growth. </p>
            </div>
          </div>
        </>}
    </section>
  );
};
