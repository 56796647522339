export const pageContent = {
  title: "Do You Want to Become Part of the wondder’s Global Partner Network?",
  subTitle: "As a wondder Global Partner Network member, you’ll gain exclusive access to tools and resources designed to help you scale your business and exceed client expectations.",
  content: "In today’s competitive market, consulting companies need solutions that not only address client challenges but also set them apart from the competition. At wondder, we’ve developed cutting-edge Virtual Reality (VR) training powered by Artificial Intelligence (AI) to transform leadership development and diversity training. Our solutions are designed to engage clients, help them develop critical skills, and drive lasting behavioral change.",
  pdf: 'caseStudies/wondder VR - Training & Consulting Firms -  Brochure.pdf',
  PDFPreview: '/static/gpn-pdf.png',
  formLink: '1WGkA2RuxRZ-EIrNGIiNdOw2elq82',
  highlights: [
    {
      "● Unique Value Propositions for your clients": "Stand out and gain forward-thinking customers by providing the latest technology on the market.",
    },
    { "● Scalable and cost-effective solution": "Boost your profitability by generating recurring revenue streams." },
    { "● Strengthen Credibility": "Partner with an established provider trusted by global brands like Accenture, Puma, Siemens & BMW." },
  ],
  workshops: [
    {
      '● Leadership Development:': "Empower leaders to improve their effectiveness, foster a positive workplace culture, and drive resilient, adaptable teams.",
    },
    {
      '● Diversity and Inclusion Training:': "Provide immersive VR experiences to enhance cultural awareness, reduce unconscious bias, and create inclusive environments for clients.",
    },
  ],
};

