import React from 'react';
import { useLocation } from 'react-router-dom';
import { successStoriesPartners } from './constants';
// import { downloadPdf } from 'helpers/utils';

export const SuccessStory = () => {
  const location = useLocation();
  const caseStudy = location.pathname.substring(15, location.pathname.length);
  const pageData = successStoriesPartners[caseStudy];

  return (
    <div className='flex flex-col px-20 py-16 bg-[#f4f8fa] md:px-8'>
      <h1 className='font-bold text-3xl mb-5 text-orange'>{pageData.title}</h1>
      <h3 className='text-xl mb-3 text-blue'>{pageData.subTitle}</h3>
      <p className='text-lg text-blue'>{pageData.content}</p>
      <div className='flex flex-col mt-8'>
        <p className='font-bold text-orange text-2xl mb-4'>Key Highlights from the Case Study:</p>
        {pageData.highlights.map((highlight, index) => {
          return (
            <div className='flex text-blue gap-x-2 text-lg mb-2 md:flex-col' key={index}>
              {Object.keys(highlight)[0] !== 'false' && <p className='font-bold whitespace-nowrap'>{Object.keys(highlight)[0]}:</p>}
              <p>{Object.values(highlight)[0]}</p>
            </div>
          );
        }
        )}
      </div>
      {pageData.workshops && <div className='flex flex-col mt-8'>
        <p className='font-bold text-orange text-2xl mb-4'>Workshops Delivered:</p>
        {pageData.workshops.map((workshop, index) => {
          return (
            <div className='flex text-blue gap-x-2 text-lg mb-2 md:flex-col' key={index}>
              <p className='font-bold whitespace-nowrap'>{Object.keys(workshop)[0]}:</p>
              <p>{Object.values(workshop)[0]}</p>
            </div>
          );
        }
        )}
      </div>}
      {pageData.resultsThatMatter && <div className='flex flex-col mt-8'>
        <p className='font-bold text-orange text-2xl mb-4'>{caseStudy === 'bmw' ? "Result That Matter" : 'The Impact'}:</p>
        {pageData.resultsThatMatter.map((res, index) => {
          return (
            <p key={index} className='text-blue gap-x-2 text-lg'>{res}</p>
          );
        }
        )}
      </div>}
      {/* <div className='flex flex-col mt-16'>
        <p className='font-bold text-xl mb-4 text-blue'>{pageData.ctaTitle ? pageData.ctaTitle : 'Download the full case study to learn how wondder’s VR training program can transform your organization.'}</p>
        <button onClick={() => downloadPdf(pageData.pdf)} className={`self-start text-lg uppercase p-2.5 !leading-none bg-blue rounded-xl text-white duration-200	border-2 border-blue min-lg:hover:bg-white min-lg:hover:text-blue min-lg:!hover:delay-0 xl:text-base sm:w-full`}>DOWNLOAD</button>
      </div> */}
      <div className='flex flex-col mt-16'>
        <p className='font-bold text-xl mb-4 text-blue self-center'>Download the Case Study Now</p>
        <div className='flex items-center self-center max-w-[400px] w-full aspect-9/16'>
          <img className='w-full h-full object-contain' src={pageData.PDFPreview} alt='PDF Preview' />
        </div>
        <div className='flex h-[55rem] w-full sm:mt-8 sm:min-w-full'>
          <iframe width='100%' height='100%' src={`https://share-eu1.hsforms.com/${pageData.formLink}`}></iframe>
        </div>
      </div>
    </div>
  );
};
