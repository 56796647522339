import React, { useMemo } from 'react';
import { teamData } from "helpers/constantData";

export const Members = () => {
  const storeTeam = useMemo(() => {
    return teamData.map((t, k) => <Member photo={t.photo} info={t.info} name={t.name} role={t.role} external={t.isExternal} key={k} />);
  }, []);

  return (
    <div className='flex flex-col items-center gap-y-36 pt-64 z-40 lg:gap-y-24 lg:pt-48 sm:!pt-16'>
      {storeTeam}
    </div>
  );
};

const Member = ({ photo, info, name, role, external }) => {
  return (
    <div className='flex items-center [&:nth-child(even)]:flex-row-reverse gap-x-10 max-w-[61rem] lg:max-w-[50rem] md:px-10 sm:!px-8 sm:!flex-col'>
      <div className='border-2 border-white rounded-full overflow-hidden relative w-96 h-96 lg:w-64 lg:h-64 sm:!w-72 sm:!h-72'>
        <img src={`${window.location.origin}/${photo}`} className='rounded-full absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[95%] h-[95%] object-cover' alt='member' />
      </div>
      <div className='flex flex-col flex-1 sm:items-center sm:mt-6'>
        <p className='text-4xl text-white font-bold uppercase lg:text-2xl sm:!text-3xl sm:text-center'>{name}</p>
        <p className='text-xl text-white font-medium mt-2 mb-5 lg:text-lg sm:!text-base sm:mb-2 sm:text-center'>
          {role}
          <span className='text-sm ml-1.5'>{external && '(external)'}</span>
        </p>
        <ul className={`flex flex-col gap-y-4 pl-4 list-disc ${name === 'Astrid Moris' || name === 'Adrien Maginot' ? 'marker:text-blue' : 'marker:text-orange'}`}>
          {info.map((i, k) => <li className='text-base text-white lg:text-sm ' key={k}>{i}</li>)}
        </ul>
      </div>
    </div>
  );
};
