export const teamData = [
  {
    name: "Mihai Streza",
    role: "CEO and Founder",
    photo: "team/4.png",
    info: [
      "Multiple tech founder, among others eRockIT, WiWo’s 2012 one of “five cleantech-startups you should know”.",
      "20+ years Personal and Leadership Development, among others: Ken Wilber's Integral Theory, Spiral Dynamics, LDMA, Tony Robbins.",
      "12+ years strategy and management (Roland Berger Strategy Consultants, Accenture Sustainability Strategy).",
      "Graduate and undergraduate education in Computer Science, Karlsruhe Institute of Technology (Robotics, Human-Computer-Interaction)."
    ],
    isExternal: false,
  },
  {
    name: "Damir Simovski",
    role: "CTO and Founder",
    photo: "team/3.png",
    info: [
      "20+ years experience in 3D creation, Virtual and Augmented Reality and visual effects.",
      "Created and supervised visual effects for over 15 film projects.",
      "Lecturer at the German Film School."
    ],
    isExternal: false,
  },
  {
    name: "Alexis Olivari",
    role: "VR Engineer",
    photo: "team/5.png",
    info: [
      "5+ years of Virtual Reality development.",
      "Experience with Augmented Reality, Mobile Reality and mobile applications.",
      "Graduate education in Computer Science, ENSIIE, École Nationale Supérieure d’Informatique pour l’Industrie et l’Entreprise."
    ],
    isExternal: true,
  },
  {
    name: "Francesca Lisi",
    role: "Head of Operations",
    photo: "team/8.png",
    info: [
      "5+ years of experience in Strategy and Management (Bain & Company strategy consultant, startup founder).",
      "Strategic thinker with a passion for personal development and innovation. Extensive experience in consulting, entrepreneurship, and driving organizational transformation.",
      "Undergraduate in Business Administration & Management and Graduate in International Management at Bocconi University and CEMS (The Global Alliance in Management Education)."
    ],
    isExternal: true,
  },
  {
    name: "Alessio Dell'Anna",
    role: "Global Partner Manager",
    photo: "team/7.png",
    info: [
      "6+ Years in international sales, driving success with tech-driven solutions and a client-focused approach across global markets.",
      "Fluent in multiple languages, excelling in diverse markets and fostering strong cross-cultural connections.",
      "BA in Foreign Languages and MA in International Relations, combining strategic thinking with a worldwide perspective."
    ],
    isExternal: true,
  },
  {
    name: "Carolina Marconi",
    role: "VR Narrative Creator",
    photo: "team/1.png",
    info: [
      "7+ years experience in VR Narrative design & implementation of AI tools in VR narrative development.",
      "Researcher at the Institute of Mechanical Intelligence, Sant'Anna School of Advanced Studies, creating VR narratives for, among others, EU-funded training on gender violence (ENGINE) and high-functioning autism support (Mental+ForU).",
      "Graduate in Ethnography and Documentary Filmmaking: VR Strand (UCL)."
    ],
    isExternal: true,
  },
  {
    name: "Junus Petersen",
    role: "Business Operations Manager",
    photo: "team/6.png",
    info: [
      "2 years of experience at wondder, planning and coordinating digital sales strategies for leadership development and DEI-focused VR training.",
      "A background in acting brings authenticity and creativity to presentations, fostering meaningful client relationships and engagement.",
      "Combining psychology, business insights, and technology to deliver transformative solutions that empower teams and individuals."
    ],
    isExternal: false,
  },
  {
    name: "Adrien Maginot",
    role: "VR Engineer",
    photo: "team/10.png",
    info: [
      "4+ years of VR and Unity Development.",
      "Editing and producing experience for more than 100 audiovisual projects.",
      "Graduate education in Computer Science, ENSIIE, École Nationale Supérieure d’Informatique pour l’Industrie et l’Entreprise."
    ],
    isExternal: true,
  },
  {
    name: "Abhishek Shinde",
    role: "VR Engineer",
    photo: "team/13.png",
    info: [
      "5+ years of XR development experience",
      "Experience with LLM-powered systems, developing VR simulations across manufacturing, healthcare, and defense sectors",
      "Bachelors in Chemical Engineering from Indian Institute of Technology, Kharagpur",
    ],
    isExternal: true,
  },
  {
    name: "Lilit Khachatryan",
    role: "Digital and AI-Driven Marketing Executive",
    photo: "team/2.png",
    info: [
      "6 years of experience in Marketing, Communications, and Digital Media.",
      "Worked on 25+ unique projects and events, cooperated individually with 15 businesses in 12 spheres.",
      "Bachelor's in Business at American University of Armenia, Master's degree in Business Communication at WU Vienna University of Economics and Business, and Université Libre de Bruxelles."
    ],
    isExternal: true,
  },
  {
    name: "Astrid Moris",
    role: "Operations Intern",
    photo: "team/11.png",
    info: [
      "Last year bachelor student in Double Degree of Business Administration in UCM and HWR",
      "Fluent in Spanish and English, working on mastering German and French. Knowledge of Spanish Sign Language.",
      "Very eager to learn, proactive, team worker, adapts easily to new situations."
    ],
    isExternal: true,
  },
  {
    name: "Vivek Jadhav",
    role: "Operations Intern",
    photo: "team/9.png",
    info: [
      "5+ years of experience in project management and operations.",
      "Experienced in collaborating with both internal and external stakeholders, with a focus on operations, inventory management, project management and continuous improvement through analytical and process enhancements.",
      "Bachelor’s degree in Mechanical Engineering from Visvesvaraya Technological University and is currently pursuing Master's in Business Administration and Engineering at Hochschule für Technik und Wirtschaft Berlin."
    ],
    isExternal: false,
  },
  {
    name: "Nicola Paul",
    role: "Lead Partner",
    photo: "team/12.png",
    info: [
      "Over a decade working in the field of culture, inclusion and ethics: driving purpose is creating high performing, collaborative teams, recognising that this is one of the greatest challenges in the working world.",
      "Master’s degree in Intercultural Communication for Businesses and is a specialist in the field of Cultural Intelligence (CQ); the third academically validated intelligence quotient alongside IQ and EQ.",
      "worked with 10% of the FTSE100 and has been a consultant for organisations such as McDonald’s, Starbucks, Marks and Spencer, Carnival UK (P&O and Cunard Cruises), Alvarez and Marsal and The UK’s Financial Conduct Authority (FCA) .",
    ],
    isExternal: true,
  },
];

