export const pageContent = {
  title: "Are You a Human Resource or Leadership Development Advisor?",
  subTitle: "Empower your leaders with immersive Virtual Reality and AI-driven training - efficient, engaging, scalable, and measurable.",
  content: "As an HR professional or Leadership development advisor, you face the challenge of equipping leaders with the skills to inspire teams, drive engagement, and measure success. wondder has redefined leadership training by integrating Virtual Reality (VR) and Artificial Intelligence (AI) to enhance engagement, ensure consistency, and provide measurable outcomes. Our innovative solutions empower organizations to develop future-ready leaders. ",
  pdf: 'caseStudies/wondder VR - HR & LD Managers - Brochure.pdf',
  PDFPreview: '/static/hr-ld-pdf.png',
  formLink: '1EGHZVLRISFezo_ZQIclYtw2elq82',
  highlights: [
    {
      "● Efficiency": "Train 75% faster than traditional methods, eliminating the need for travel and lengthy workshops.",
    },
    { "● Immersive Engagement": "VR-based learning creates 3.75 times more emotional connection, ensuring that lessons are retained and applied effectively." },
    { "● Scalability": "Deliver consistent, high-quality training across locations while reducing costs by 50%, without the constraints of facilitator availability or scheduling conflicts." },
  ],
  workshops: [
    {
      '● Leadership Development Training:': "Improve leadership effectiveness and promote a positive, empowered workplace culture.",
    },
    {
      '● Diversity and Inclusion Training:': "Enhance awareness and sensitivity while building inclusive workplaces where everyone thrives.",
    },
  ],
};

